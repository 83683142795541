/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getNotifications } from '../fn/in-app-notifications/get-notifications';
import { GetNotifications$Params } from '../fn/in-app-notifications/get-notifications';
import { getNotificationsCount } from '../fn/in-app-notifications/get-notifications-count';
import { GetNotificationsCount$Params } from '../fn/in-app-notifications/get-notifications-count';
import { inAppNotificationsUuidArchivePatch } from '../fn/in-app-notifications/in-app-notifications-uuid-archive-patch';
import { InAppNotificationsUuidArchivePatch$Params } from '../fn/in-app-notifications/in-app-notifications-uuid-archive-patch';
import { inAppNotificationsUuidReadPatch } from '../fn/in-app-notifications/in-app-notifications-uuid-read-patch';
import { InAppNotificationsUuidReadPatch$Params } from '../fn/in-app-notifications/in-app-notifications-uuid-read-patch';
import { PaginatedNotifications } from '../models/paginated-notifications';

@Injectable({ providedIn: 'root' })
export class InAppNotificationsApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getNotifications()` */
  static readonly GetNotificationsPath = '/in-app-notifications';

  /**
   * Retrieve all the in-app notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotifications$Response(params: GetNotifications$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginatedNotifications>> {
    return getNotifications(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve all the in-app notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotifications(params: GetNotifications$Params, context?: HttpContext): Observable<PaginatedNotifications> {
    return this.getNotifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaginatedNotifications>): PaginatedNotifications => r.body)
    );
  }

  /** Path part for operation `getNotificationsCount()` */
  static readonly GetNotificationsCountPath = '/in-app-notifications/count';

  /**
   * Retrieve the in-app notifications count.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotificationsCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationsCount$Response(params?: GetNotificationsCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getNotificationsCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve the in-app notifications count.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNotificationsCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationsCount(params?: GetNotificationsCount$Params, context?: HttpContext): Observable<number> {
    return this.getNotificationsCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `inAppNotificationsUuidArchivePatch()` */
  static readonly InAppNotificationsUuidArchivePatchPath = '/in-app-notifications/{uuid}/archive';

  /**
   * Archive a notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inAppNotificationsUuidArchivePatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inAppNotificationsUuidArchivePatch$Response(params: InAppNotificationsUuidArchivePatch$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return inAppNotificationsUuidArchivePatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Archive a notification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inAppNotificationsUuidArchivePatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inAppNotificationsUuidArchivePatch(params: InAppNotificationsUuidArchivePatch$Params, context?: HttpContext): Observable<void> {
    return this.inAppNotificationsUuidArchivePatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `inAppNotificationsUuidReadPatch()` */
  static readonly InAppNotificationsUuidReadPatchPath = '/in-app-notifications/{uuid}/read';

  /**
   * Archive a notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inAppNotificationsUuidReadPatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inAppNotificationsUuidReadPatch$Response(params: InAppNotificationsUuidReadPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return inAppNotificationsUuidReadPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Archive a notification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inAppNotificationsUuidReadPatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inAppNotificationsUuidReadPatch(params: InAppNotificationsUuidReadPatch$Params, context?: HttpContext): Observable<void> {
    return this.inAppNotificationsUuidReadPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
